export const SET_OPEN_ZENDESK = 'zendesk/SET_OPEN_ZENDESK';
export const SET_HIDE_ZENDESK = 'zendesk/SET_HIDE_ZENDESK';
export const INIT_ACCOUNT_DATA_ZENDESK = 'zendesk/INIT_ACCOUNT_DATA_ZENDESK';
export const ACCOUNT_DATA_LOADED_ZENDESK = 'zendesk/ACCOUNT_DATA_LOADED_ZENDESK';
export const SEND_VISITOR_MESSAGE = 'zendesk/SEND_VISITOR_MESSAGE';
export const SEND_VISITOR_FILE = 'zendesk/SEND_VISITOR_FILE';
export const END_CHAT = 'zendesk/END_CHAT';

export const ZENDESK_CHAT_MEMBER_JOIN = 'chat.memberjoin';
export const ZENDESK_CHAT_MEMBER_LEAVE = 'chat.memberleave';
export const ZENDESK_CHAT_WAIT_QUEUE = 'chat.wait_queue';
export const ZENDESK_CHAT_QUEUE_POSITION = 'chat.queue_position';
export const ZENDESK_CHAT_FILE = 'chat.file';
export const ZENDESK_CHAT_MSG = 'chat.msg';
export const ZENDESK_CHAT_REQUEST_RATING = 'chat.request.rating';
export const ZENDESK_CHAT_RATING = 'chat.rating';
export const ZENDESK_CHAT_TYPING = 'typing';

export const zendeskEvents = {
  CONNECTION_UPDATE: 'zendesk/CONNECTION_UPDATE',
  ACCOUNT_STATUS: 'zendesk/ACCOUNT_STATUS',
  DEPARTMENT_UPDATE: 'zendesk/DEPARTMENT_UPDATE',
  VISITOR_UPDATE: 'zendesk/VISITOR_UPDATE',
  AGENT_UPDATE: 'zendesk/AGENT_UPDATE',
  CHAT: 'zendesk/CHAT',
};
